import React from 'react';
import './Home.scss';
import picture from '../../img/Web_photo-05.png';
import arrow from '../../img/arrow.png';

function Home({ linkRef }) {
    return (
        <section ref={linkRef} id="home-page" className="home">
            <div className="home__container">
                <div className="home__container__introduction">
                    <div className="home__container__introduction__container-1">
                        Hi
                        <span
                            className="comma"
                            style={{
                                marginRight: '10px'
                            }}
                        >
                            {','}
                        </span>
                        I
                        <span className="apostrophe">{`'`}</span>
                        m Martynas
                    </div>
                    <div className="home__container__introduction__container-2">
                        <div className="home__container__introduction__container-2__text-container">
                            <img className="arrow" src={arrow} alt="arrow" />
                            <div>
                                <span className="text-neon-green">front-end </span>
                                <span>developer</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home__container__photo">
                    <img className="home__container__photo__image" src={picture} alt="my_photo" />
                </div>
            </div>
        </section>
    );
}

export default Home;
