import React, { useRef, useEffect } from 'react';
import './Carousel.scss';

function Carousel({ img }) {
    const scroll = useRef();
    useEffect(() => {
        scroll.current.addEventListener('wheel', (e) => {
            e.preventDefault();
            scroll.current.scrollLeft += e.deltaY * 4;
        });
    }, []);
    const toLeft = () => {
        scroll.current.scrollLeft -= scroll.current.clientWidth / 2;
    };

    const toRight = () => {
        scroll.current.scrollLeft += scroll.current.clientWidth / 2;
    };

    return (
        <>
            <button onClick={toLeft}>I Kaire</button>
            <div className="gallery-box">
                <div ref={scroll} className="scroll-container">
                    {img.map((image, index) => (
                        <div
                            key={index}
                            className="image"
                            style={{
                                backgroundColor: image.color
                            }}
                        >
                            {image.color}
                        </div>
                    ))}
                </div>
            </div>
            <button onClick={toRight}>I Desine</button>
        </>
    );
}

export default Carousel;
