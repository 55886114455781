import './Navigation.scss';

function Navigation({ linkAbout, linkContacts, linkPortfolio, scrollToHash, display }) {
    return (
        <div className="nav" ref={display}>
            <div
                className="nav__item"
                role="link"
                tabIndex={0}
                onClick={() => scrollToHash(linkAbout)}
                onKeyPress={() => scrollToHash(linkAbout)}
            >
                About
            </div>
            <div className="nav__line">
                <div className="nav__undeline" />
            </div>

            <div
                className="nav__item"
                role="link"
                tabIndex={0}
                onClick={() => scrollToHash(linkPortfolio)}
                onKeyPress={() => scrollToHash(linkPortfolio)}
            >
                Portfolio
            </div>
            <div className="nav__line">
                <div className="nav__undeline" />
            </div>

            <div
                className="nav__item"
                role="link"
                tabIndex={0}
                onClick={() => scrollToHash(linkContacts)}
                onKeyPress={() => scrollToHash(linkContacts)}
            >
                Contact
            </div>
        </div>
    );
}

export default Navigation;
