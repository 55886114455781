import './Brand.scss';

function Brand({ scrollToHash, linkHome }) {
    return (
        <div
            className="brand"
            tabIndex={0}
            role="link"
            onClick={() => scrollToHash(linkHome)}
            onKeyPress={() => scrollToHash(linkHome)}
        >
            <a>
                <svg className="brand__logo">
                    <use href="#logo_cat" />
                </svg>
            </a>
        </div>
    );
}

export default Brand;
