import React from 'react';
import './Contact.scss';

function Contact({ linkRef }) {
    return (
        <section ref={linkRef} id="contacts" className="contact">
            <div className="contact__container">
                <div className="contact__container__main">
                    <div className="contact__container__main__text-1--font-style">Contact me</div>
                    <div className="contact__container__main__text-2--font-style">come say hi</div>
                </div>
                <div className="contact__container__main__email">
                    <img
                        className="contact__container__main__email__me"
                        src="images/email.png"
                        alt=""
                    />
                    <div
                        className="
                    contact__container__main__email__my_mail
                    contact__container__main__email__my_mail--font-color
                    contact__container__main__email__my_mail--font-size
                    "
                    >
                        martynas.kupsys13@gmail.com
                    </div>
                </div>
                <div className="contact__container__main__other">
                    <a href="https://www.facebook.com/KovinisAsiliukas">
                        <img
                            className="contact__container__main__other__fb"
                            src="images/fb.png"
                            alt=""
                            srcSet=""
                        />
                    </a>
                    <a href="https://www.linkedin.com/in/martynas-kup%C5%A1ys/">
                        <img
                            className="contact__container__main__other__linkedin"
                            src="images/linkedin.png"
                            alt=""
                            srcSet=""
                        />
                    </a>
                    <a href="https://github.com/MartynasKupsys">
                        <img
                            className="contact__container__main__other__github"
                            src="images/github.png"
                            alt=""
                            srcSet=""
                        />
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Contact;
