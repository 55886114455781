/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './About.scss';
import techs from '../../Assets/techs/techs';

function About({ linkRef }) {
    return (
        <section ref={linkRef} id="about" className="about">
            <div className="about-container">
                <div className="about-container__me">
                    <div
                        className="
                        about-container__me__header
                        about-container__me__header--font-size
                        about-container__me__header--font-color
                        about-container__me__header--letter-space"
                    >
                        About me
                    </div>
                    <div
                        className="
                    about-container__me__text
                    about-container__me__text--font-color"
                    >
                        <p className="about-container__me__text__p about-container__me__text__p--font-size">
                            Fresh Front-end developer, more than ready to fully dive into this
                            field, learn from every task, solve problems and find best possible
                            solutions. A team player, who is driven by goals and challenges.
                        </p>
                        <p className="about-container__me__text__p about-container__me__text__p--font-size">
                            <span className="span-text span-text--font-size span-text--font-color">
                                Keywords:
                            </span>
                            {' '}
                            persistence, productivity, teamwork, analysis, adaptability, problem
                            solving, attention to details, time management, communication
                        </p>
                    </div>
                </div>
                <div className="about-container__skills">
                    <div
                        className="
                    about-container__skills__header
                    about-container__skills__header--font-size
                    about-container__skills__header--font-color
                    about-container__skills__header--letter-space"
                    >
                        Skills
                    </div>
                    <div className="about-container__skills__tech">
                        <div className="about-container__skills__tech__items">
                            {techs.map((tech, i) => (
                                <div key={i} className={`tech-${i + 1}`}>
                                    <img
                                        className="tech-image"
                                        src={`images/${tech.id}.png`}
                                        alt=""
                                        srcSet=""
                                    />
                                </div>
                            ))}
                            <div className="tech-10">to be continued...</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
