import React, { useEffect, useRef, useState } from 'react';
import './Header.scss';
import * as ReactDOM from 'react-dom';
import Navigation from './Navigation/Navigation.jsx';
import Brand from './Brand/Brand.jsx';

function Header({ linkHome, linkAbout, linkPortfolio, linkContacts }) {
    const scrollToHash = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth'
        });
    };

    const display = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
        });
    }, []);

    const openMeniu = () => {
        if (shouldShow) {
            setTimeout(() => {
                setShouldShow((p) => !p);
            }, 500);
            display.current.classList.remove('fade-in');
            display.current.classList.add('fade-out');
        } else {
            setShouldShow((p) => !p);
        }
    };

    useEffect(() => {
        if (!shouldShow) return;
        display.current.classList.add('fade-in');
    }, [shouldShow]);

    return width <= 768 ? (
        <div className="navigation-container">
            <Brand scrollToHash={scrollToHash} linkHome={linkHome} />
            {/* Meniu */}
            <div
                className="hamburger"
                tabIndex={0}
                role="button"
                onKeyPress={() => openMeniu()}
                onClick={() => openMeniu()}
            >
                <div className="spans">
                    <span className="span" />
                    <span className="span" />
                    <span className="span" />
                </div>
            </div>
            {shouldShow ? (
                <Navigation
                    display={display}
                    scrollToHash={scrollToHash}
                    linkAbout={linkAbout}
                    linkPortfolio={linkPortfolio}
                    linkContacts={linkContacts}
                />
            ) : null}
        </div>
    ) : (
        <div className="navigation-container">
            {/* Brand */}
            <Brand scrollToHash={scrollToHash} linkHome={linkHome} />
            <Navigation
                scrollToHash={scrollToHash}
                linkAbout={linkAbout}
                linkPortfolio={linkPortfolio}
                linkContacts={linkContacts}
            />
        </div>
    );
}

export default Header;
