import React, { useRef, useState } from 'react';
import './main.scss';
import './Bootstrap.css';
import Home from './sections/Home';
import Portfolio from './sections/Portfolio';
import Contact from './sections/Contact';
import About from './sections/About';
import Header from './components/Header';

function App() {
    const linkAbout = useRef();
    const linkPortfolio = useRef();
    const linkContacts = useRef();
    const linkHome = useRef();

    const img = [
        {
            id: 1,
            color: '#ee88a0'
        },
        {
            id: 2,
            color: '#9dfdcc'
        },
        {
            id: 3,
            color: '#4e6015'
        },
        {
            id: 4,
            color: '#633552'
        },
        {
            id: 5,
            color: '#b98999'
        },
        {
            id: 6,
            color: '#4cb24b'
        },
        {
            id: 7,
            color: '#67c802'
        },
        {
            id: 8,
            color: '#f5325c'
        },
        {
            id: 9,
            color: '#a39ca6'
        },
        {
            id: 10,
            color: '#5e37ab'
        },
        {
            id: 11,
            color: '#e72711'
        },
        {
            id: 12,
            color: '#4ac4ed'
        },
        {
            id: 13,
            color: '#2bd364'
        },
        {
            id: 14,
            color: '#43ddbd'
        },
        {
            id: 15,
            color: '#642bee'
        }
    ];

    return (
        <>
            <Header
                linkHome={linkHome}
                linkAbout={linkAbout}
                linkPortfolio={linkPortfolio}
                linkContacts={linkContacts}
            />
            <Home linkRef={linkHome} />
            <About linkRef={linkAbout} />
            <Portfolio linkRef={linkPortfolio} img={img} />
            <Contact linkRef={linkContacts} />
        </>
    );
}

export default App;
