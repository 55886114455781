import React from 'react';
import Carousel from '../../components/Carousel/Carousel.jsx';
import './Portfolio.scss';

function Portfolio({ linkRef, img }) {
    // console.log('I rendered portfolio');

    return (
        <section ref={linkRef} id="portfolio" className="portfolio">
            <Carousel img={img} />
        </section>
    );
}

export default Portfolio;
