const techs = [
    {
        name: 'ReactJS', id: 'react'
    },
    {
        name: 'NodeJS', id: 'node'
    },
    {
        name: 'JavaScript', id: 'js'
    },
    {
        name: 'SASS', id: 'sass'
    },
    {
        name: 'BootStrap', id: 'bootstrap'
    },
    {
        name: 'PHP', id: 'php'
    },
    {
        name: 'HTML5', id: 'html'
    },
    {
        name: 'CSS', id: 'css'
    },
    {
        name: 'SQL', id: 'sql'
    }
];

export default techs;
